import React from 'react';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
const Home = React.lazy(() => import('../../home/Home'));
const Features = React.lazy(() => import('../../features/Features'));
const AboutUs = React.lazy(() => import('../../aboutus/AboutUs'));
const Contact = React.lazy(() => import('../../contact/Contact'));
const Impressum = React.lazy(() => import('../../common/Impressum/Impressum'));
const PrivacyPolicy = React.lazy(() => import('../../common/PrivacyPolicy/PrivacyPolicy'));
const TermsCondition = React.lazy(() => import('../../common/TermsCondition/TermsCondition'));
import LazyLoad from 'react-lazyload';

const App = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense>
              <LazyLoad>
                <Home />
              </LazyLoad>
            </Suspense>
          }
        />
        <Route
          path="/home"
          element={
            <Suspense>
              <LazyLoad>
                <Home />
              </LazyLoad>
            </Suspense>
          }
        />
        <Route
          path="/features"
          element={
            <Suspense>
              <LazyLoad>
                <Features />
              </LazyLoad>
            </Suspense>
          }
        />
        <Route
          path="/aboutUs"
          element={
            <Suspense>
              <LazyLoad>
                <AboutUs />
              </LazyLoad>
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense>
              <LazyLoad>
                <Contact />
              </LazyLoad>
            </Suspense>
          }
        />
        <Route
          path="/impressum"
          element={
            <Suspense>
              <Impressum />
            </Suspense>
          }
        />
        <Route
          path="/privacy"
          element={
            <Suspense>
              <LazyLoad>
                <PrivacyPolicy />
              </LazyLoad>
            </Suspense>
          }
        />
        <Route
          path="/termsCondition"
          element={
            <Suspense>
              <TermsCondition />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default App;
