import en from '../../assets/locale/en.json';
import de from '../../assets/locale/de.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};
const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    detection: DETECTION_OPTIONS,
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react alread y safes from xss
    },
  });

export default i18n;
